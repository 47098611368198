import React from 'react'
import ProductCard from './ProductCard';

const Tunic = () => {
    return (
        <section className="flex justify-center items-center flex-col" >
            <h1 className="text-2xl mt-5" > <span className="trending-line "></span>Tunic</h1>

            <div className="flex justify-around items-center flex-wrap " >
                <ProductCard src="../img/Tunic (1).jpeg" name="Tunic" dic="Shop tunic tops. Versatile and comfortable, they're perfect for any occasion." />

                <ProductCard src="../img/Tunic (5).jpeg" name="Tunic" dic="Shop tunic tops. Versatile and comfortable, they're perfect for any occasion." />
                <ProductCard src="../img/Tunic (6).jpeg" name="Tunic" dic="Shop tunic tops. Versatile and comfortable, they're perfect for any occasion." />

                <ProductCard src="../img/Tunic (2).jpeg" name="Tunic" dic="Shop tunic tops. Versatile and comfortable, they're perfect for any occasion." />
                <ProductCard src="../img/Tunic (3).jpeg" name="Tunic" dic="Shop tunic tops. Versatile and comfortable, they're perfect for any occasion." />
                <ProductCard src="../img/Tunic (4).jpeg" name="Tunic" dic="Shop tunic tops. Versatile and comfortable, they're perfect for any occasion." />

                <ProductCard src="../img/Tunic (7).jpeg" name="Tunic" dic="Shop tunic tops. Versatile and comfortable, they're perfect for any occasion." />




            </div>

        </section>
    )
}

export default Tunic;