
const CallIcon = () => {
    return (
        <>
            <div className='fixed left-0 bottom-0  flex justify-center items-center ' style={{ width: "55px", height: "55px", borderRadius: "50%", background: "linear-gradient(#0dccea, #0d70ea)" }} >
                <a href="tel:+919693912345" className="text-white text-2xl" ><i className="fa-solid fa-phone"></i></a>
            </div>
        </>
    )
}

export default CallIcon;