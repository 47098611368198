import Header from "./Home.jsx/Header";
import TrendingProduct from "./Home.jsx/LastesProduct";
import ContactForm from "./Home.jsx/ContactForm";
import CollectionHome from "./Home.jsx/CollectionHome";
import UserReview from "./Home.jsx/UserReview";



const MasterHome = () => {
    return (
        <>
            <Header />
            <TrendingProduct />
            <CollectionHome />
            <UserReview />
            <ContactForm />
            
        </>
    )
}

export default MasterHome;