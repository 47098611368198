import { Link } from "react-router-dom";


const Nav = () => {
  return (
    <nav className='flex justify-around h-16 w-full nav sticky  top-0 z-50 '   >
    <Link to="/" style={{width:"65px"}} ><img src="https://firebasestorage.googleapis.com/v0/b/ranchipro-ee5c8.appspot.com/o/logo.png?alt=media&token=7ebe39bc-441b-4b59-83e7-64d99453d361" alt="logo botique" /></Link>

    <div className='flex justify-around items-center ' style={{letterSpacing:"1px"}} >
       <Link to="/collection" className='button buttonH'  >Collections</Link>
       <Link to="/contact" className='button buttonH'  >Contact</Link>
    </div>
 </nav>
  )
}

export default Nav;