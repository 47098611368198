import { Link, Outlet } from "react-router-dom";

const AllCollections = () => {
    return (
        <section className=' mt-2 mb-2 flex justify-start items-center flex-col' >
            <div className='w-full flex  justify-center  items-start flex-row  list flex-wrap ' >
                <Link to="blouse" className='button text-sm' >Blouse</Link>
                <Link to="Saree" className='button text-sm' >Saree</Link>
                {/* <Link to="Dupatta" className='button text-sm' >Dupatta</Link> */}
                <Link to="Lahanga" className='button text-sm' > lehenga</Link>
                <Link to="Suit" className='button text-sm' >Suit</Link>
                {/* <Link to="Frowk" className='button text-sm' >Frowk </Link> */}
                <Link to="Gown" className='button text-sm' >Gown</Link>
                {/* <Link to="MachinaandHandWork" className='button text-sm' >machine & Handwork</Link> */}
                <Link to="tunic" className='button text-sm' >Tunic</Link>

                <div className='w-full flex  justify-center  items-start flex-row  list flex-wrap ' >
                    <Outlet />
                </div>


            </div>



        </section>
    )
}

export default AllCollections;