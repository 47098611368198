import MAster from "./MAster";
function App() {
  return (
    <div >
           <MAster />
    </div>
  );
}

export default App;
