import ProductCard from "./ProductCard"


const Lahanga = () => {
  return (
    <section className="flex justify-center items-center flex-col" >
      <h1 className="text-2xl mt-5" > <span className="trending-line "></span>Lahanga</h1>

      <div className="flex justify-around items-center flex-wrap " >
        <ProductCard src="../img/LL11.jpg" name=" lehenga" dic="Elevate your look with our exquisite designer lehengas." />
        <ProductCard src="../img/LL12.jpg" name=" lehenga" dic="Elevate your look with our exquisite designer lehengas." />
        <ProductCard src="../img/LL1.jpg" name=" lehenga" dic="Elevate your look with our exquisite designer lehengas." />
        <ProductCard src="../img/LL8.jpg" name=" lehenga" dic="Elevate your look with our exquisite designer lehengas." />
        <ProductCard src="../img/LL2.jpg" name=" lehenga" dic="Elevate your look with our exquisite designer lehengas." />
        <ProductCard src="../img/LL3.jpg" name=" lehenga" dic="Elevate your look with our exquisite designer lehengas." />
        <ProductCard src="../img/LL4.jpg" name=" lehenga" dic="Elevate your look with our exquisite designer lehengas." />
        <ProductCard src="../img/LL5.jpg" name=" lehenga" dic="Elevate your look with our exquisite designer lehengas." />
        <ProductCard src="../img/LL6.jpg" name=" lehenga" dic="Elevate your look with our exquisite designer lehengas." />
        <ProductCard src="../img/LL7.jpg" name=" lehenga" dic="Elevate your look with our exquisite designer lehengas." />
        <ProductCard src="../img/LL9.jpg" name=" lehenga" dic="Elevate your look with our exquisite designer lehengas." />
        <ProductCard src="../img/LL10.jpg" name=" lehenga" dic="Elevate your look with our exquisite designer lehengas." />
      </div>
    </section>
  )
}

export default Lahanga