import ProductCard from "./ProductCard"

const Gown = () => {
  return (
    <section className="flex justify-center items-center flex-col" >
    <h1 className="text-2xl mt-5" > <span className="trending-line "></span>Gown</h1>

    <div className="flex justify-around items-center flex-wrap " >
      <ProductCard src="../img/Gowun.jpeg" name="Gown" dic="Gowns exude timeless elegance. Discover our collection for any occasion" />
    

    </div>

  </section>
  )
}

export default Gown