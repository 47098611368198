import React from 'react'

const Contact = () => {
    return (
        <section className=' flex justify-center items-center flex-col' >
            <div className='w-10/12  mt-2 flex justify-center items-center flex-col ' >
                <div className="text-center max-w-xl mx-auto">
                    <h1 className="text-2xl md:text-4xl font-bold m2-5 text-gray-600">About Us</h1>
                    <div className="text-center mb-10">
                        <span className="inline-block w-1 h-1 rounded-full" style={{ background: " #f19021" }}  ></span>
                        <span className="inline-block w-3 h-1 rounded-full" style={{ background: " #f19021" }} ></span>
                        <span className="inline-block w-40 h-1 rounded-full " style={{ background: " #f19021" }} ></span>
                        <span className="inline-block w-3 h-1 rounded-full" style={{ background: " #f19021" }} ></span>
                        <span className="inline-block w-1 h-1 rounded-full" style={{ background: " #f19021" }} ></span>
                    </div>
                </div>

                <div className='flex justify-center items-center flex-col ' >
                    <img src="../img/shope.jpeg" alt="shope_imag"
                        width="500px"
                        height="400px"
                    />


                    <ol className='mt-5 mb-5' >

                       <p>
                       "Ranchi Boutique is in the heart of city at CAPITOL HILL Main Road Ranchi. Our City is known as a city of Joy where fashionable people live so we are here to fulfill their  desire of fashion by serving them  fashionable dresses that can match their personality. We have the experience of 40 glorious years in the field of Tailoring. But in the field of ladies fashion, Ranchi Boutique has been established in 2013 and completed about glorious 10 years in this boutique field with 100 % satisfied customers base.Here, our professional fashion designers do research on recent fashion trend and interpret to the customers as per their personality. We have profound knowledge in this field and we better know your requirment so we suggest you best dress and design that suits you. We have very skilled tailors and experienced craftsmanship who has been in this field for years which make them distinguished from others. This boutique is the choice of every fashion lover because we make dresses for every occassion which makes it one stop solution shop. That's why it is a place where fashion meets your attitude...

                       </p>
                    </ol>

                    <h1 className="text-2xl md:text-4xl font-bold m2-5 text-gray-600">Contact us...</h1>

                    <div className='flex justify-around items-center flex-col md:flex-row mt-5' >
                        <i className="fa-solid fa-location-dot mr-8 mb-5 md:mb-2" style={{ fontSize: "68px", color: " #f19021" }} ></i>
                        <div className='fulla' >
                            <h2> Mahatma Gandhi Marg</h2>
                            <p>(Main Road 1st Floor, Shop no. - 22 Capitol Hill)</p>
                            <p> Ranchi, Jharkhand 834001 </p>
                            <p>ranchiboutique@gmail.com</p>
                            <p>+9196939-12345</p>

                        </div>
                    </div>

                </div>


            </div>


        </section>
    )
}

export default Contact;