import Nav from "./Component/Home.jsx/Nav";
import Footer from "./Component/Home.jsx/Footer";
import MasterHome from "./Component/MasterHome";
import { Route, Routes } from "react-router-dom";
import AllCollections from "./Component/AllCollections/AllCollections";
import Blouse from "./Component/AllCollections/Blouse";
import Dupatta from "./Component/AllCollections/Dupatta";
import Frowk from "./Component/AllCollections/Frowk";
import Gown from "./Component/AllCollections/Gown";
import Lahanga from "./Component/AllCollections/Lahanga";
import Saree from "./Component/AllCollections/Saree";
import Suit from "./Component/AllCollections/Suit";
import MachinaandHandWork from "./Component/AllCollections/MachinaandHandWork";
import CallIcon from "./Component/CallIcon";
import Contact from "./Component/Contact/Contact";
import WhatshapIcon from "./Component/WhatshapIcon";
import Tunic from "./Component/AllCollections/Tunic";

const MAster = () => {
  return (
    <>
      <Nav />
      <Routes>
        <Route exact path="/" element={<MasterHome />} />
        <Route exact path="*" element={<MasterHome />} />
        <Route exact path="/Contact" element={<Contact />} />
        <Route path="/collection" element={<AllCollections />} >
          <Route index element={<Blouse />} />
          <Route path="blouse" element={<Blouse />} />
          <Route path="Dupatta" element={<Dupatta />} />
          <Route path="Frowk" element={<Frowk />} />
          <Route path="Gown" element={<Gown />} />
          <Route path="Lahanga" element={<Lahanga />} />
          <Route path="Saree" element={<Saree />} />
          <Route path="Suit" element={<Suit />} />
          <Route path="tunic" element={<Tunic />} />

          <Route path="MachinaandHandWork" element={<MachinaandHandWork />} />
        </Route>
      </Routes>
      <CallIcon />
      <WhatshapIcon />
      <Footer />

    </>
  )
}

export default MAster;