import ProductCard from "./ProductCard"

const Blouse = () => {
  return (
    <section className="flex justify-center items-center flex-col" >
      <h1 className="text-2xl mt-5" > <span className="trending-line "></span>Blouse</h1>

      <div className="flex justify-around items-center flex-wrap " >
       

    

        <ProductCard src="../img/Blouses (4).jpeg"  name="Blouse" dic = "Our exquisite blouse collection features unique designs to elevate your style." />

        <ProductCard src="../img/Blouse (5).jpeg"  name="Blouse" dic = "Our exquisite blouse collection features unique designs to elevate your style." />

      

        <ProductCard src="../img/Blouses (2).jpeg"  name="Blouse" dic = "Our exquisite blouse collection features unique designs to elevate your style." />

        <ProductCard src="../img/Blouses (3).jpeg"  name="Blouse" dic = "Our exquisite blouse collection features unique designs to elevate your style." />
      
        <ProductCard src="../img/Blouse (1).jpeg"  name="Blouse" dic = "Our exquisite blouse collection features unique designs to elevate your style." />

        <ProductCard src="../img/Blouse (6).jpeg"  name="Blouse" dic = "Our exquisite blouse collection features unique designs to elevate your style." />

      </div>

    </section>
  )
}

export default Blouse