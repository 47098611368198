import { Link } from "react-router-dom";

const CollectionHome = () => {
    return (
        <section className='  flex justify-center items-center flex-col mt-5 pb-5' >
            <h1 className="text-2xl" > <span className="trending-line "></span> All Collections</h1>
            <div className='w-10/12  flex justify-center items-center  flex-wrap mt-4 ' >
                <Link to="/collection/blouse" className='button' >Blouse</Link>
                <Link to="/collection/Saree" className='button' >Saree</Link>
                {/* <Link to="/collection/Dupatta" className='button' >Dupatta</Link> */}
                <Link to="/collection/Lahanga" className='button' >Lahanga</Link>
                <Link to="/collection/Suit" className='button' >Suit</Link>
                {/* <Link to="/collection/Frowk" className='button' >Frowk </Link> */}
                <Link to="/collection/Gown" className='button' >Gown</Link>
                {/* <Link to="/collection/MachinaandHandWork" className='button' >Handwork & machine work</Link> */}
                <Link to="/collection/tunic" className='button' >Tunic</Link>


            </div>
        </section>
    )
}

export default CollectionHome;
