import { useState } from "react";
import { Watch } from "react-loader-spinner";
import swal from "sweetalert";
import { databse, storage, app } from "../Firebase/Firebase";
import { addDoc, collection } from "firebase/firestore";


const ContactForm = () => {
    const [loader, setloeader] = useState(false);

    const collectionRef = collection(databse, "user")


    const [userdata, setuserdata] = useState({ username: "", number: "", email: "", msg: "" });

    const mychange = (e) => {
        if (e.target.name === "name") {
            setuserdata({ username: e.target.value })
        } else if (e.target.name === "email") {
            setuserdata({ ...userdata, email: e.target.value })
        } else if (e.target.name === "number") {
            setuserdata({ ...userdata, number: e.target.value })
        } else if (e.target.name === "msg") {
            setuserdata({ ...userdata, msg: e.target.value })
        }
    }


    const mysub = (e) => {
        e.preventDefault();
        setloeader(true)
        addDoc(collectionRef, {
            name: userdata.username,
            email: userdata.email,
            number: userdata.number,
            msg: userdata.msg
        }).then((done) => {
            swal({
                title: "Thanks ",
                icon: "success",
                buttons: false,
                timer: 3000
            });
            setloeader(false)

        }).catch((eroor) => {
            swal({
                title: eroor.message,
                icon: "error",
                buttons: false,
                timer: 3000
            });
            setloeader(false)

        })

    }


    return (
        <section className="mt-5 mb-5" >
            <h1 className="text-2xl text-center" > <span className="trending-line "></span>Welcome Your Questions</h1>
            <div className="wrapper mt-5">
                <form className="form" onSubmit={mysub}  >
                    <input type="text" className="name formEntry" placeholder="Your Name ... " required value={userdata.username || ""} onChange={mychange} name="name" />

                    <input type="text" className="email formEntry" placeholder="Email (optionol)" value={userdata.email || ""} onChange={mychange} name="email" />

                    <input type="number" className="email formEntry" placeholder="Your Number.." required value={userdata.number || ""} onChange={mychange} name="number" />


                    <textarea className="message formEntry" placeholder="Message" value={userdata.msg || ""} onChange={mychange} name="msg"   ></textarea>


                    <button className="submit formEntry" type="submite" >{loader ? <div className="w-full h-full flex justify-center items-center" ><Watch width={"40"} height={40} color="white" /></div> : "Submit"}</button>
                </form>
            </div>
        </section>
    )
}
export default ContactForm;