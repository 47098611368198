import React from 'react'

const LetestCard = ({ src, name , para }) => {
  return (
    <div className='Letest_card' >
      <img src={src} alt="" style={{ width: "100%" }} />

      <div className='pb-2' >
        <h5 className='ml-5 mt-2 mb-2' style={{ fontWeight: "700" }} >{name}</h5>
        <p className='ml-5 text-sm mb-2' >{para}</p>
        <span className='ml-5  py-1 pl-3 pr-3' style={{ backgroundColor: "#f7c202" }} >Best Sale</span>
      </div>
    </div>
  )
}

export default LetestCard;