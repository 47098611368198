


const ProductCard = ({ src , name  , dic }) => {
  return (
    <div className='Letest_card ml-5 mr-5' >
       <img src={src} alt="lahnaga image" />

      <div className='pb-2' >
        <h5 className='ml-5 mt-2 mb-2' style={{ fontWeight: "700" }} >{name}</h5>
        <p className='ml-5 text-sm mb-2' style={{color:"rgb(119 119 119)"}} >{dic}</p>
      </div>
    </div>
  )
}

export default ProductCard;