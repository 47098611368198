import ProductCard from "./ProductCard"


const Saree = () => {
  return (
    <section className="flex justify-center items-center flex-col" >
      <h1 className="text-2xl mt-5" > <span className="trending-line "></span>Saree</h1>

      <div className="flex justify-around items-center flex-wrap " >
        <ProductCard src="../img/Sari (1).jpeg" name="saree" dic="Find your perfect saree with our collection of elegant styles." />
        
        <ProductCard src="../img/Saris (2).jpeg" name="saree" dic="Find your perfect saree with our collection of elegant styles." />
        
    

      </div>

    </section>
  )
}

export default Saree