import ProductCard from "./ProductCard"


const MachinaandHandWork = () => {
  return (
    <section className="flex justify-center items-center flex-col" >
      <h1 className="text-2xl mt-5" > <span className="trending-line "></span>MachinaandHandWork</h1>

      <div className="flex justify-around items-center flex-wrap " >
        <ProductCard />
        <ProductCard />
        <ProductCard />
        <ProductCard />
        <ProductCard />

      </div>

    </section>
  )
}

export default MachinaandHandWork