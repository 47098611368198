import React from 'react'

const Footer = () => {
    return (
        <footer className='w-full flex  justify-center  items-center mt-16 footer pt-12 flex-col' >

            <div className=' w-full flex justify-center  md:justify-around  items-center flex-col md:flex-row md:pl-24 md:mt-2' >
                <div  className='flex justify-start items-center mb-5' >
                    <img src="https://firebasestorage.googleapis.com/v0/b/ranchipro-ee5c8.appspot.com/o/logo.png?alt=media&token=7ebe39bc-441b-4b59-83e7-64d99453d361" alt="logo" height="50px" width="100px" />
                    <div>

            
                        
                        <h4> <i className="fa-solid fa-location-dot"></i> Mahatma Gandhi Marg </h4>
                        <h5>Main Road 1st Floor Capitol Hill</h5>
                        <p>( Shop no. - 22 ) Ranchi</p>
                        <p>+9196939-12345</p>
                    </div>
                </div>

                <div className='socila-link' >
                    <a href="https://www.facebook.com/RANCHIBOUTIQUE?mibextid=ZbWKwL"><i style={{color:"#4267B2"}} className="fa-brands fa-facebook"></i></a>
                    <a href="https://instagram.com/ranchiboutique?igshid=ZDdkNTZiNTM="><i style={{color:"#962fbf"}} className="fa-brands fa-instagram"></i></a>
                  
                </div>

                <hr />

            </div>
                <p className='mt-2 mb-2' >© 2023 ranchiboutique</p>

        </footer>
    )
}

export default Footer;
