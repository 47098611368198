import ProductCard from "./ProductCard"


const Suit = () => {
  return (
    <section className="flex justify-center items-center flex-col" >
      <h1 className="text-2xl mt-5" > <span className="trending-line "></span>suite</h1>

      <div className="flex justify-around items-center flex-wrap " >
        <ProductCard src="../img/Suite (1).jpeg" name="suit" dic="Elevate your formalwear with our stylish suit collection" />
        <ProductCard src="../img/Suite (2).jpeg" name="suit" dic="Elevate your formalwear with our stylish suit collection" />
        <ProductCard src="../img/Suite (3).jpeg" name="suit" dic="Elevate your formalwear with our stylish suit collection" />
        <ProductCard src="../img/Suite (4).jpeg" name="suit" dic="Elevate your formalwear with our stylish suit collection" />
        <ProductCard src="../img/Suite (5).jpeg" name="suit" dic="Elevate your formalwear with our stylish suit collection" />

        <ProductCard src="../img/Suite (6).jpeg" name="suit" dic="Elevate your formalwear with our stylish suit collection" />

        <ProductCard src="../img/Suite (7).jpeg" name="suit" dic="Elevate your formalwear with our stylish suit collection" />

        <ProductCard src="../img/Suite (8).jpeg" name="suit" dic="Elevate your formalwear with our stylish suit collection" />

        <ProductCard src="../img/Suite (9).jpeg" name="suit" dic="Elevate your formalwear with our stylish suit collection" />


      </div>

    </section>
  )
}

export default Suit