import LetestCard from "./LetestCard";




const LastesProduct = () => {
    return (
        <section className=" mt-10  h-sccren flex justify-start items-center  flex-col "  >
            <div className="font-serif tracking-wide  text-left mb-3" >  <h1 className="text-2xl" > <span className="trending-line "></span> Trending Design </h1></div>
            <div className="w-4/5  flex justify-around items-center flex-wrap " >
                <LetestCard src="img/T1.jpg" name="Latest Suite" para="Sustainable, minimalist fashion with bright hues and relaxed fits reigns" />
                <LetestCard src="img/T2.jpg" name="Latest Suite" para="Sustainable, minimalist fashion with bright hues and relaxed fits reigns" />
                <LetestCard src="img/T3.jpg" name="Latest Tunic" para="Upgrade your wardrobe with our latest stylish and unique tunics." />
                <LetestCard src="img/T4.jpg" name="Latest Suite" para="Sustainable, minimalist fashion with bright hues and relaxed fits reigns" />
                <LetestCard src="img/T5.jpg" name="Latest Suite" para="Sustainable, minimalist fashion with bright hues and relaxed fits reigns" />
                <LetestCard src="img/T6.jpg" name="Latest Tunic" para="Upgrade your wardrobe with our latest stylish and unique tunics." />
                <LetestCard src="img/T7.jpg" name="Latest Suite" para="Sustainable, minimalist fashion with bright hues and relaxed fits reigns" />
                <LetestCard src="img/T8.jpg" name="Latest Suite" para="Sustainable, minimalist fashion with bright hues and relaxed fits reigns" />
                <LetestCard src="img/T9.jpg" name="Latest Suite" para="Sustainable, minimalist fashion with bright hues and relaxed fits reigns" />

            </div>

        </section>
    )
}

export default LastesProduct;