import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCKYiVAa2utj3P1GhLpjTfoLxyPznCDuuU",
    authDomain: "ranchipro-ee5c8.firebaseapp.com",
    projectId: "ranchipro-ee5c8",
    storageBucket: "ranchipro-ee5c8.appspot.com",
    messagingSenderId: "998629115472",
    appId: "1:998629115472:web:0ee1c51e436c1f99373355"
};


export  const app = initializeApp(firebaseConfig);
export const databse = getFirestore(app);
export const storage = getStorage(app);
