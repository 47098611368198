
const WhatshapIcon = () => {
    return (
        <div className='fixed bottom-16  left-0 flex justify-center items-center ' style={{ width: "55px", height: "55px", background: "linear-gradient(#0dccea, #0d70ea)", borderRadius: "50%" }}  >
            <a href="https://api.whatsapp.com/send?phone=+919693912345&amp;text=Hi%20ranchi boutique,%20I%20contacted%20you%20Through%20your%20website." className='text-2xl' target="_blank" ><i className="fa-brands fa-whatsapp" style={{ color: "white" }} ></i></a>
        </div>
    )
}

export default WhatshapIcon;

