import { useState } from "react";

const Header = () => {

  const slides = [
    {
      url:"img/image22.png"
    },
    {
      url:"img/image3.png"
    },
    {
      url:"img/image5.png"
    },
    {
      url:"img/image6.png"
    },
    {
      url:"img/image7.png"
    },
    {
      url:"img/image9.png"
    },
    {
      url:"img/image10.png"
    },
  
    
    
  ];


  const [currentIndex, setCurrentIndex] = useState(0);


  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  // linear-gradient(rgba(0, 0, 0, 0.1), rgb(155 114 20 / 93%)), url(img/image6.png);


  return (
    <header style={{background: "linear-gradient(125deg, rgba(175,208,254,1) 29%, rgba(234,221,243,1) 58%)"}}  >
      <div className='w-full  m-auto  relative group' style={{height:"90vh"}} >
        <div  
         onMouseEnter={nextSlide}
          style={{
            
            
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(115, 114, 20, 93%)), url(${slides[currentIndex].url})`
            
            
            
            
            }}
          className='w-full h-full bg-center flex items-end justify-center  bg-cover duration-500 bg-mage'
        >

          {/* dot bnt */}

          <div className='flex justify-center items-end py-2'>
            {slides.map((slide, slideIndex) => (
              <div
                key={slideIndex}
                onClick={() => goToSlide(slideIndex)}
                className='text-2xl cursor-pointer'
              >
                <span className="btn-smoll" >.</span>
              </div>
            ))}
          </div>

        </div>
        {/* Left Arrow */}
        <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl p-2 bg-black/20 text-white cursor-pointer'>
          <i className="fa-solid fa-chevron-left" onClick={prevSlide} size={30} ></i>
        </div>
        {/* Right Arrow */}
        <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl p-2 bg-black/20 text-white cursor-pointer'>
          <i className="fa-solid fa-chevron-right" onClick={nextSlide} size={30} > </i>
        </div>

      </div>
    </header>
  )
}

export default Header;
